<template>
  <div>
    <div class="backgroundOV"></div>
    <div>
      <v-card outlined>
        <v-card-title class="titleLarge"
          >Zahlverständnis - Kompetenzliste</v-card-title
        >
      </v-card>
    </div>

    <br />
    <br />

    <div v-for="(n, index) in tables.length" :key="n">
      <v-card>
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr style="text-align: center">
                <td colspan="3">
                  {{ tables[index].title }}
                  <div v-if="index === 0" style="text-align: center">
                    (Hinweis: Alle Angaben auf dieser Seite werden automatisch
                    gespeichert)
                  </div>
                </td>
              </tr>
              <tr>
                <td><strong>Kompetenz: Ich kann...</strong></td>
                <td width="140px" class="no-wrap" style="text-align: center">
                  <strong>unsicher - sicher</strong>
                </td>
                <td width="35%">
                  <strong>Meine Kommentare</strong>
                </td>
              </tr>
              <tr v-for="(m, index1) in tables[index].checks.length" :key="m">
                <td>
                  {{ tables[index].checks[index1].description }}
                </td>
                <td>
                  <v-radio-group
                    dense
                    row
                    v-model="tables[index].checks[index1].level"
                    @change="saveState"
                  >
                    <v-radio
                      v-for="o in 4"
                      :key="o"
                      :value="o"
                      style="margin-right: -7px; margin-left: 0px;  justify-content: center;"
                    ></v-radio>
                  </v-radio-group>
                </td>
                <td>
                  <v-textarea
                    full-width
                    auto-grow
                    rows="0"
                    label="Kommentar"
                    @input="saveState"
                    v-model="tables[index].checks[index1].comment"
                    data-matomo-unmask
                  ></v-textarea>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
      <br />
      <br />
    </div>

    <v-btn
      class="white--text"
      x-large
      color="#477B7E"
      to="/home"
      style="float: right"
    >
      <v-icon>mdi-arrow-left</v-icon>
      Zurück zur Themenübersicht
    </v-btn>

    <AppBottomNavZV back="/zahlverstaendnis/diagnose-und-foerderung" />
  </div>
</template>

<script>
import AppBottomNavZV from "@/common/AppBottomNavZV";
export default {
  components: {
    AppBottomNavZV,
  },
  mounted: function() {
    this.restoreState();
  },
  data() {
    return {
      tables: [
        {
          title: "Zahlverständnis: Grundvorstellungen von Zahlen",
          checks: [
            {
              description:
                "… den kardinalen und ordinalen Zahlaspekt (und die Teilaspekte) allgemein erklären.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… grundschulgemäße Beispiele für beide Zahlaspekte anbringen.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… die grundlegenden Zählprinzipien allgemein erklären.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… Zählfehler anhand der Zählprinzipien identifizieren.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… das Zählen eines Kindes begründet der jeweiligen Entwicklungsphase der Zählentwicklung zuordnen.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… erklären, weshalb der Erwerb der Zählkompetenz und der Aufbau tragfähiger Zahlvorstellungen eine zentrale Aufgabe der Schuleingangsphase ist.",
              level: 0,
              comment: "",
            },
          ],
        },
        {
          title: "Zahlverständnis: Darstellungen vernetzen",
          checks: [
            {
              description:
                "… die verschiedenen Darstellungsweisen benennen und Beispiele für diese angeben.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… erklären, was mit einer Darstellungsvernetzung gemeint ist und dies grundschulgemäß anhand geeigneter Beispiele verdeutlichen.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… benennen, warum das Lesen, Schreiben und Sprechen von Zahlwörtern für die Lernenden eine Lernhürde bedeuten kann.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… begründen, warum Darstellungswechsel für ein tragfähiges Zahlverständnis elementar sind.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… Simultanerfassung und Quasisimultanerfassung allgemein erklären.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… erklären, welche Vorteile strukturierte Darstellungen für den Aufbau des Zahlverständnisses bieten.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… benennen, was Lernende in Bezug auf das Vernetzen von Darstellungen wissen sollten.",
              level: 0,
              comment: "",
            },
          ],
        },
        {
          title: "Zahlverständnis: Zusammenhänge zwischen Zahlen",
          checks: [
            {
              description: "… die wesentlichen Zahlbeziehungen benennen.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… das Teil-Ganzes-Konzept allgemein erklären und zeigen, warum es wichtig für den Aufbau tragfähiger Zahlvorstellungen ist.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… die Entwicklung der Fähigkeit zum Mengenvergleich skizzieren.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… die zwei wesentlichen Vorgehensweisen des Mengenvergleichs voneinander unterscheiden.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… erläutern, inwiefern ordinale Zahlvorstellungen ebenfalls wichtig für ein tragfähiges Zahlverständnis sind.",
              level: 0,
              comment: "",
            },
          ],
        },
        {
          title: "Zahlverständnis: Diagnose und Förderung",
          checks: [
            {
              description:
                "… die drei zentralen Hintergrundfacetten des Zahlverständnisses nennen und erklären, warum dieses Hintergrundwissen für die Diagnose und Förderung von Lernenden wichtig ist.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… typische Fehler im Bereich des Zahlverständnisses nachvollziehen und nach Gemeinsamkeiten ordnen.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… bereits vorhandene Kompetenzen im Bereich des Zahlverständnisses anhand von Kinderdokumenten erkennen und beschreiben.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… Fehler im Bereich des Zahlverständnisses anhand von Kinderdokumenten erkennen und beschreiben.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… Ursachen für Fehler im Bereich des Zahlverständnisses anhand von Kinderdokumenten begründet ableiten.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… passgenaue Diagnoseaufgaben auswählen und diese Auswahl auch begründen.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… Zählfehler anhand der Zählprinzipien identifizieren.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… das Zählen eines Kindes begründet der jeweiligen Entwicklungsphase der Zählentwicklung zuordnen.",
              level: 0,
              comment: "",
            },
            {
              description:
                "… anhand von Kinderdokumenten diagnostizieren, welcher Zahlaspekt ausgeprägt / nicht ausgeprägt ist.",
              level: 0,
              comment: "",
            },
            {
              description: "... diagnosegeleitet Förderaufgaben auswählen.",
              level: 0,
              comment: "",
            },
          ],
        },
      ],
    };
  },
  methods: {
    restoreState: function() {
      const storedId = "CheckZV";

      const restoredState = this.$store.state.falediaState
        ? this.$store.state.falediaState[storedId]
        : false;

      if (restoredState) {
        this.tables = restoredState.data.tables;
      }
      if (!this.$store.hasModule(["nested", storedId])) {
        this.$store.registerModule(["nested", storedId], {
          namespaced: true,
          state: {
            data: {},
          },
          mutations: {
            data(state, payload) {
              state.data = {
                tables: payload.tables,
              };
            },
          },
        });
        this.saveStateLocal();
      }
    },

    saveStateLocal: function() {
      const storedId = "CheckZV";
      this.$store.commit("nested/" + storedId + "/data", { ...this._data });
    },

    saveState: function() {
      this.saveStateLocal();
      //send data to server
      const token = localStorage.getItem("jwt");
      try {
        this.$http
          .post("/user/state", this.$store.state.nested, {
            headers: { Authorization: token },
          })
          .then((response) => {
            localStorage.setItem("jwt", response.data.token);
            this.$store.commit("falediaState", this.$store.state.nested);
          })
          .catch(() => {
            //if there is an error, we do not save the state
          });
      } catch (err) {
        //if there is an error, we do not save the state
      }
    },
  },
};
</script>

<style scoped>
#app .no-wrap {
  white-space: nowrap;
  overflow: auto;
}
#app .titleLarge {
  font-weight: normal;
  font-size: xx-large;
}
#app .titleSmaller {
  font-weight: normal;
  font-size: x-large;
}
#app .tdButton {
  position: absolute;
  right: -10px;
  bottom: -5px;
}
</style>
